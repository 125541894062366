import React, { useRef, useEffect } from "react";
import logo from "../assets/Image/logo.png";

const AboutUs = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.currentTime = 5;
        }
    }, []);

    return (
        <React.Fragment>
            <div className="font-poppins mb-10 mt-16">
                <div className="mx-auto mt-2 mb-10 ">
                    <h2 className="font-semibold text-center text-2xl">
                        <span className="text-[#20AC18]">Alog</span>Flow AI is Here For You
                    </h2>
                </div>
                <div className="relative overflow-hidden mx-auto max-w-[95%] rounded-2xl sm:h-screen  md:max-w-[90%] lg:max-w-[95%]">
                    <video
                        ref={videoRef}
                        controls
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full "
                    >
                        <source src="https://turbofinn-ai.s3.eu-north-1.amazonaws.com/TF-AI.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="relative z-10 flex items-center font-poppins justify-end h-full ">
                        <h1 className=" sm:text-3xl max-w-[47%] font-semibold leading-relaxed mr-10  bg-white bg-opacity-40 p-3 rounded-md">
                            Transform your business with AI-
                            <span className=" text-[#0A5906]">driven automation solutions. From </span>
                            advanced Software and intelligent process automation
                        </h1>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AboutUs;
