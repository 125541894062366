import React, { useState, useEffect } from "react"
import CLarrow from "../assets/Image/CLarrow.png";
import CRarrow from "../assets/Image/CRarrow.png";
import cg01 from "../assets/Image/cg01.png";
import cg02 from "../assets/Image/cg02.png";
import cg03 from "../assets/Image/cg03.png";
import cw01 from "../assets/Image/cw01.png";
import cw02 from "../assets/Image/cw02.png";
import cw03 from "../assets/Image/cw03.png";
import profile from "../assets/Image/profile.png";
import comma from "../assets/Image/comma01.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Ahana_Bhanduri from "../assets/Image/Customer/Ahana_Bhanduri.jpeg";
import Amit_Singh from "../assets/Image/Customer/Amit_Singh.jpeg";
import Gunjesh_Sahu from "../assets/Image/Customer/Gunjesh_Sahu.jpeg";
const Customer = () => {
  const customer_data = [
    // [
      {
        name: "Ahana Bhanduri",
        image: Ahana_Bhanduri,
        designation: "Orthopedic Surgeon",
        message: "The AI service implemented in our hospital has significantly enhanced efficiency and patient care.Development team delivered that exceeded our expectations."
      },
      {
        name: "Amit Singh",
        image: Amit_Singh,
        designation: "Jaisuda Dairy",
        message: "The dairy management and delivery tracking application has streamlined our entire operation. It is user-friendly, efficient, and has greatly improved our delivery process. We are highly satisfied with the results!"
      },
      {
        name: "Gunjesh Sahu",
        image: Gunjesh_Sahu,
        designation: " Parkkey Pvt Ltd",
        message: "The parking management system is intuitive, reliable, and has greatly improved our parking operations. The team provided an excellent solution that met all our needs."
      }
  ]

  const customer_data01 = [
    {
      name: "Ahana Bhanduri",
      image: Ahana_Bhanduri,
      designation: "Orthopedic Surgeon",
      message: "The AI service implemented in our hospital has significantly enhanced efficiency and patient care.Development team delivered that exceeded our expectations."
    },
    {
      name: "Amit Singh",
      image: Amit_Singh,
      designation: "Jaisuda Dairy",
      message: "The dairy management and delivery tracking application has streamlined our entire operation. It is user-friendly, efficient, and has greatly improved our delivery process. We are highly satisfied with the results!"
    },
    {
      name: "Gunjesh Sahi",
      image: Gunjesh_Sahu,
      designation: " Parkkey Pvt Ltd",
      message: "The parking management system is intuitive, reliable, and has greatly improved our parking operations. The team provided an excellent solution that met all our needs."
    },

  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 900, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 780, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ]
  };
  return (
    <React.Fragment>
      <div className=" font-poppins w-[95%] lg:max-w-[90%] mx-auto  mt-16">
        <div className=" flex flex-row justify-between items-center w-[90%] mx-auto">
          <img src={CLarrow} alt="CLarrow" className=" h-8 lg:h-12 hover:cursor-pointer" />
          <h1 className=" text-center text-2xl lg:text-4xl font-bold w-[80%] md:w-[50%] lg:w-[40%] lg:leading-relaxed ">Was The Saying Of Our Customer</h1>
          <img src={CRarrow} alt="CRarrow" className=" h-8 lg:h-12 hover:cursor-pointer" />
        </div>

        <div className=" hidden md:block max-w-[95%] lg:max-w-[90%] mx-auto lg:mt-5">
                <div className="mt-5  ">
                  <div className=" flex flex-row justify-between w-[95%] lg:w-[85%] mx-auto mt-10">
                    <div className=" bg-cover bg-center bg-no-repeat w-[175px] h-[240px] lg:w-[220px] lg:h-[300px] "
                      style={{ backgroundImage: `url(${cg01})` }}>
                      <div className=" bg-cover bg-center bg-no-repeat w-[175px] h-[245px]  lg:w-[220px] lg:h-[310px]"
                        style={{ backgroundImage: `url(${cw01})` }}>
                        <div className=" flex flex-col justify-center items-center my-auto p-6">
                          <img src={customer_data[0].image} alt="profile" className=" mx-auto w-10 lg:w-16 rounded-full" />
                          <h1 className=" font-medium text-center text-sm lg:text-base">{customer_data[0].name}</h1>
                          <h3 className=" text-center text-xs lg:text-sm text-gray-600">{customer_data[0].designation}</h3>
                          <img src={comma} alt="comma" className=" mx-auto w-3 lg:w-5 mt-1" />
                          <p className="  text-center text-[9px] lg:text-xs text-gray-500 italic mt-2">{customer_data[0].message}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" bg-cover bg-center bg-no-repeat w-[240px] h-[280px] lg:w-[300px] lg:h-[350px] "
                      style={{ backgroundImage: `url(${cg02})` }}>
                      <div className=" bg-cover bg-center bg-no-repeat w-[240px] h-[280px]  lg:w-[300px] lg:h-[350px] "
                        style={{ backgroundImage: `url(${cw02})` }}>
                        <div className=" flex flex-col justify-center items-center my-auto">
                          <img src={customer_data[1].image} alt="profile" className=" mx-auto -mt-[10%] w-16 lg:w-20 rounded-full" />
                          <h1 className=" font-semibold lg:text-xl text-center  transition-all lg:mt-2 ease-in-out duration-500">{customer_data[1].name}</h1>
                          <h3 className=" text-center text-sm lg:text-base text-gray-600 lg:mt-1 transition-all ease-in-out duration-500">{customer_data[1].designation}</h3>
                          <img src={comma} alt="comma" className=" mx-auto w-4 lg:w-7 mt-2" />
                          <p className=" text-center text-xs lg:text-sm text-gray-500 italic mt-4 transition-all ease-in-out duration-500 max-w-[90%] mx-auto">{customer_data[1].message}</p>
                        </div>
                      </div>
                    </div>

                    <div className=" bg-cover bg-center bg-no-repeat w-[175px] h-[240px] lg:w-[220px] lg:h-[300px] "
                      style={{ backgroundImage: `url(${cg03})` }}>
                      <div className=" bg-cover bg-center bg-no-repeat w-[175px] h-[245px] lg:w-[220px] lg:h-[310px] "
                        style={{ backgroundImage: `url(${cw03})` }}>
                        <div className=" flex flex-col justify-center items-center my-auto p-6">
                          <img src={customer_data[2].image} alt="profile" className=" mx-auto w-10 lg:w-16 rounded-full" />
                          <h1 className=" font-medium text-center text-sm lg:text-base">{customer_data[2].name}</h1>
                          <h3 className=" text-center text-xs lg:text-sm text-gray-600">{customer_data[2].designation}</h3>
                          <img src={comma} alt="comma" className=" mx-auto w-3 lg:w-5 mt-1" />
                          <p className="  text-center text-[9px] lg:text-xs text-gray-500 italic mt-2">{customer_data[2].message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        </div>

        <div className="block md:hidden max-w-[95%]">
          <Slider {...settings}>
            {customer_data01.map((data, index) => (
              <div key={index} className="mt-10">
                <div className="flex justify-center w-[90%] lg:w-[85%] mx-auto mt-2">
                  <div className="bg-cover bg-center bg-no-repeat w-[240px] h-[280px]"
                    style={{ backgroundImage: `url(${cg02})` }}>
                    <div className="bg-cover bg-center bg-no-repeat w-[240px] h-[280px]"
                      style={{ backgroundImage: `url(${cw02})` }}>
                      <div className="flex flex-col justify-center items-center my-auto">
                        <img src={data.image} alt="profile" className="mx-auto -mt-[10%] w-16 rounded-full" />
                        <h1 className="font-semibold lg:text-xl text-center transition-all ease-in-out duration-500">{data.name}</h1>
                        <h3 className="text-center text-sm lg:text-base text-gray-600 lg:mt-2 transition-all ease-in-out duration-500">{data.designation}</h3>
                        <img src={comma} alt="comma" className="mx-auto w-5 lg:w-7 mt-2" />
                        <p className="text-center text-xs lg:text-sm text-gray-400 italic mt-4 transition-all ease-in-out duration-500 max-w-[90%] mx-auto">{data.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

      </div>
    </React.Fragment>
  )
}

export default Customer;