import React, { useState } from "react";
import contact from "../assets/Image/contact.jpg";
import dropdown1 from "../assets/Image/dropdown1.png";
import { LineWave, TailSpin } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const Modal = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const submitHandler = async (e) => {
        setLoading(true);        
        e.preventDefault();
        try {
            // const response = await axios.post('https://k0t4wp6v69.execute-api.ap-south-1.amazonaws.com/crm', {
            //     body: JSON.stringify({
            //         Last_Name: name,
            //         Email: email,
            //         Phone: phone,
            //         Website: 'www.turbofinn.com',
            //         Company: service
            //     })
            // }, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            // console.log(response.data);
            // // toast.success('Your request are successfully submitted')
            // setName('');
            // setEmail('');
            // setPhone('');
            // setMessage('');
            // setService('Select a service');
            // setLoading(false);
            props.closeloader();
            props.fromSubmitted();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="p-2 lg:p-0 lg:mt-10 max-w-[100%] lg:max-w-[90%] mx-auto rounded-3xl font-inter">
            <div className="w-full mx-auto">
                <div className="xl:gap-20">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-10 mt-8 lg:mt-0">
                        <div>
                            <div className="max-w-[100%] mx-auto">
                                <h1 className="text-4xl font-bold text-[#1E3B0B]">Let's connect for your inquiry</h1>
                                <h2 className="text-[#1E3B0B] mt-5">Within the next 24 hours, one of our dedicated team members will personally connect with you.</h2>
                            </div>
                            <img src={contact} alt="Contact" className="w-[400px] mx-auto" />
                        </div>
                        <div className="py-5 bg-white rounded-md relative">
                            <img src={dropdown1} alt="Dropdown" className="bg-white rounded-full mx-auto absolute top-[-12px] h-8 right-0 left-0 animate-bounce" />
                            <h2 className="text-[#1E3B0B] text-2xl text-center py-3 lg:py-6">Share your contact with us.</h2>
                            <form className="max-w-[87%] lg:max-w-[100%] mx-auto my-auto" onSubmit={submitHandler}>
                                <div className="grid gap-3 mb-6 md:grid-cols-2">
                                    <div>
                                        <label htmlFor="name" className="block mb-2 text-sm text-gray-900">Name</label>
                                        <input type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={name} onChange={(e) => { setName(e.target.value) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-900">Email</label>
                                        <input type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>
                                    <div>
                                        <label htmlFor="service" className="block mb-2 text-sm text-gray-900">Select Service</label>
                                        <select id="service" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={service} onChange={(e) => { setService(e.target.value) }}>
                                            <option value="">Select a service</option>
                                            <option value="AI solution">A.I. Solution</option>
                                            <option value="web development">Web Development</option>
                                            <option value="mobile app">Mobile App</option>
                                            <option value="ui ux design">UI/UX Design</option>
                                            <option value="workflow Automation">Workflow Automation</option>
                                            <option value="CRM Integration">CRM Integration</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="phone" className="block mb-2 text-sm text-gray-900">Phone number</label>
                                        <input type="tel" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="message" className="block mb-2 text-sm text-gray-900">Message</label>
                                    <textarea id="message" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[150px]" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                </div>
                                <button type="submit" className="px-4 py-2 bg-[#1E3B0B] hover:bg-white hover:border hover:border-[#1E3B0B] hover:text-[#1E3B0B] transition-all ease-linear duration-300 shadow-xl hover:shadow-2xl text-white rounded-md">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
                <Toaster />
            </div>
            { loading && (<div className="fixed inset-0  z-[99999] flex items-center justify-center backdrop-blur-sm ">
                    <div className={`fixed flex max-w-[16%] max-h-[10%] lg:max-w-[8%] lg:max-h-[8%] my-auto mx-auto items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none shadow-2xl ${ loading ? 'opacity-100' : 'opacity-0'} bg-opacity-0`}>
                        <div className="relative mx-auto  ">
                            <TailSpin
                                visible={true}
                                height="50"
                                width="50"
                                color="#076C02"
                                ariaLabel="tail-spin-loading"
                                radius="3"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>

                    </div>
                </div>)}
        </section>
    );
};

export default Modal;
