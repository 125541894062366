import React, { useRef } from "react";
import webdev from "../assets/Image/webdev.png";
import appdev from "../assets/Image/appdev.png";
import crm from "../assets/Image/crm.png";
import workflow from "../assets/Image/workflow.png";
import uiux from "../assets/Image/uiux.png";
import aisolution from "../assets/Image/alsolution.png";
import react from "../assets/Image/react.png";
import java from "../assets/Image/java.png";
import ruby from "../assets/Image/rubby.png";
import { useState, useEffect } from "react";
import Portfolio from "../assets/PDF/Portfolio.pdf"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// web development
import angular from "../assets/Image/angular.png";
import django from "../assets/Image/django.png";
import Gatsby from "../assets/Image/Gatsby.png";
import laravel from "../assets/Image/laravel.png";
import nextjs from "../assets/Image/nextjs.png";
import nodejs from "../assets/Image/nodejs.png";
import rails from "../assets/Image/rails.png";
import spring from "../assets/Image/spring.png";
import vuejs from "../assets/Image/vuejs.png";

// ui ux
import adobe from "../assets/Image/UIUX/adobe.png";
import axure from "../assets/Image/UIUX/axure.png";
import framer from "../assets/Image/UIUX/framer.png";
import invision from "../assets/Image/UIUX/invision.png";
import marvel from "../assets/Image/UIUX/marvel.png";
import principle from "../assets/Image/UIUX/principle.svg";
import sketch from "../assets/Image/UIUX/sketch.png";
import xd from "../assets/Image/UIUX/xd.png";
import zeplin from "../assets/Image/UIUX/Zeplin.png";

// app dev
import android_studio from "../assets/Image/app_dev/android_studio.png";
import dart from "../assets/Image/app_dev/dart.png";
import firebase from "../assets/Image/app_dev/firebase.png";
import flutter from "../assets/Image/app_dev/flutter.png";
import graphQL from "../assets/Image/app_dev/graphQL.png";
import kotlin from "../assets/Image/app_dev/kotlin.jpg";
// import react from "../assets/Image/app_dev/react.jpg";
import realm from "../assets/Image/app_dev/realm.png";
import swift from "../assets/Image/app_dev/swift.png";
import xcode from "../assets/Image/app_dev/xcode.png";

// CRM
import dynamics_365 from "../assets/Image/CRM/dynamics_365.png";
import freshsales from "../assets/Image/CRM/freshsales.png";
import HUBSpot from "../assets/Image/CRM/HUBSpot.png";
import inshightly from "../assets/Image/CRM/Inshightly.png";
import nimble from "../assets/Image/CRM/nimble.jfif";
import pipedrive from "../assets/Image/CRM/pipedrive.png";
import salesforce from "../assets/Image/CRM/salesforce.png";
import sugarcrm from "../assets/Image/CRM/sugarcrm.webp";
import zendesk_sell from "../assets/Image/CRM/zendesk_sell.png";
import ZOHO from "../assets/Image/CRM/ZOHO.webp";

// AI solution
import Amazon_SageMaker from "../assets/Image/AIsolution/Amazon_SageMaker.png";
import Azure_ML from "../assets/Image/AIsolution/Azure_ML.png";
import chatGPT from "../assets/Image/AIsolution/chatGPT.png";
import Google_Cloud_AI from "../assets/Image/AIsolution/Google_Cloud_AI.png";
import H2O from "../assets/Image/AIsolution/H2O.png";
import IBM_Watson from "../assets/Image/AIsolution/IBM_Watson.png";
import Keras from "../assets/Image/AIsolution/Keras.png";
import PyTorch from "../assets/Image/AIsolution/PyTorch.png";
import Scikit_learn from "../assets/Image/AIsolution/Scikit_learn.png";
import TF from "../assets/Image/AIsolution/TF.jpg";


// workflow automation

import alteryx from "../assets/Image/WorkFlow/alteryx.png";
import apache_airflow from "../assets/Image/WorkFlow/apache_airflow.png";
import Automation_Anywhere from "../assets/Image/WorkFlow/Automation_Anywhere.avif";
import blue_prism from "../assets/Image/WorkFlow/blue_prism.png";
import integromat from "../assets/Image/WorkFlow/Integromat.png";
import Microsoft_Power_Automate from "../assets/Image/WorkFlow/Microsoft_Power_Automate.png";
import Nintex from "../assets/Image/WorkFlow/Nintex.png";
import UiPath from "../assets/Image/WorkFlow/UiPath.png";
import Workato from "../assets/Image/WorkFlow/Workato.png";
import Zapier from "../assets/Image/WorkFlow/Zapier.jpg";


import {
  motion,
  AnimatePresence,
  useAnimationControls,
  animate,
} from "framer-motion";

const ServicesModify = (props) => {
  const tech = [
    {
      image: react,
      name: "React",
      about: "For Web & Mobile Development"
    },
    {
      image: java,
      name: "Java",
      about: "For Web & Mobile Development"
    },
    {
      image: ruby,
      name: "Ruby",
      about: "For Web & Mobile Desinging"
    },
    {
      image: react,
      name: "React",
      about: "For Web & Mobile Development"
    },
    {
      image: java,
      name: "Java",
      about: "For Web & Mobile Development"
    },
    {
      image: ruby,
      name: "Ruby",
      about: "For Web & Mobile Desinging"
    },
    {
      image: react,
      name: "React",
      about: "For Web & Mobile Development"
    },
    {
      image: java,
      name: "Java",
      about: "For Web & Mobile Development"
    },
    {
      image: ruby,
      name: "Ruby",
      about: "For Web & Mobile Desinging"
    },
  ]
  const [content, setContent] = useState([
    {
      name: "UI / UX  Development.",
      help: [
        "Enhanced User Satisfaction",
        "Increased Accessibility and Usability",
        "Boosted Efficiency and Productivity",
        "Reduced Development Costs and Time"
      ],
      image: uiux,
      show: false,
      tech: [
        {
          techImg: adobe,
          techName: "Adobe",
          about: "UI / UX  Development"
        },
        {
          techImg: axure,
          techName: "Axure",
          about: "UI / UX  Development"
        },
        {
          techImg: framer,
          techName: "Framer",
          about: "UI / UX  Development"
        },
        {
          techImg: invision,
          techName: "Invision",
          about: "UI / UX  Development"
        },
        {
          techImg: marvel,
          techName: "Marvel",
          about: "UI / UX  Development"
        },
        {
          techImg: principle,
          techName: "Principle",
          about: "UI / UX  Development"
        },
        {
          techImg: sketch,
          techName: "Sketch",
          about: "UI / UX  Development"
        },
        {
          techImg: xd,
          techName: "XD",
          about: "UI / UX  Development"
        },
        {
          techImg: zeplin,
          techName: "Zeplin",
          about: "UI / UX  Development"
        },
      ]
    },
    {
      name: "Application Development.",
      help: [
        "Clear Documentation and Tutorials",
        "Community Support and Forums",
        "Responsive Customer Support",
        "Regular Updates and Feedback Integration"
      ],
      image: appdev,
      show: false,
      tech: [
        {
          techImg: android_studio,
          techName: "Android Studio",
          about: "Application Development"
        },
        {
          techImg: dart,
          techName: "Dart",
          about: "Application Development"
        },
        {
          techImg: firebase,
          techName: "Firebase",
          about: "Application Development"
        },
        {
          techImg: flutter,
          techName: "Flutter",
          about: "Application Development"
        },
        {
          techImg: graphQL,
          techName: "GraphQL",
          about: "Application Development"
        },
        {
          techImg: kotlin,
          techName: "Kotlin",
          about: "Application Development"
        },
        {
          techImg: react,
          techName: "React",
          about: "Application Development"
        },
        {
          techImg: realm,
          techName: "Realm",
          about: "Application Development"
        },
        {
          techImg: swift,
          techName: "Swift",
          about: "Application Development"
        },
        {
          techImg: xcode,
          techName: "Xcode",
          about: "Application Development"
        },
      ]
    },
    {
      name: "Website Development.",
      help: [
        "Design and User Experience (UX)",
        "Front-end and Back-end Development",
        "Performance Optimization",
        "Security and SEO"
      ],
      image: webdev,
      show: true,
      tech: [
        {
          techImg: angular,
          techName: "Angular",
          about: "Website Development"
        },
        {
          techImg: django,
          techName: "Django",
          about: "Website Development"
        },
        {
          techImg: Gatsby,
          techName: "Gatsby",
          about: "Website Development"
        },
        {
          techImg: laravel,
          techName: "Laravel",
          about: "Website Development"
        },
        {
          techImg: nextjs,
          techName: "Next js",
          about: "Website Development"
        },
        {
          techImg: nodejs,
          techName: "Node js",
          about: "Website Development"
        },
        {
          techImg: rails,
          techName: "Rails",
          about: "Website Development"
        },
        {
          techImg: react,
          techName: "React",
          about: "Website Development"
        },
        {
          techImg: ruby,
          techName: "Ruby",
          about: "Website Development"
        },
        {
          techImg: spring,
          techName: "Spring",
          about: "Website Development"
        },
        {
          techImg: vuejs,
          techName: "Vue js",
          about: "Website Development"
        },
      ]
    },
    {
      name: "CRM Integration.",
      help: [
        "Enhanced Customer Data Management",
        "Improved Customer Experience",
        "Streamlined Business Processes",
        "Data-Driven Decision Making"
      ],
      image: crm,
      show: false,
      tech: [
        {
          techImg: dynamics_365,
          techName: "Dynamics 365",
          about: "CRM Integration"
        },
        {
          techImg: freshsales,
          techName: "Fresh Sales",
          about: "CRM Integration"
        },
        {
          techImg: HUBSpot,
          techName: "HUB Spot",
          about: "CRM Integration"
        },
        {
          techImg: inshightly,
          techName: "Inshightly",
          about: "CRM Integration"
        },
        {
          techImg: nimble,
          techName: "Nimble",
          about: "CRM Integration"
        },
        {
          techImg: pipedrive,
          techName: "Pipedrive",
          about: "CRM Integration"
        },
        {
          techImg: salesforce,
          techName: "Salesforce",
          about: "CRM Integration"
        },
        {
          techImg: sugarcrm,
          techName: "Sugar CRM",
          about: "CRM Integration"
        },
        {
          techImg: zendesk_sell,
          techName: "Zendesk Sell",
          about: "CRM Integration"
        },
        {
          techImg: ZOHO,
          techName: "ZOHO",
          about: "CRM Integration"
        },
      ]
    },
    {
      name: "A.I Solution",
      help: [
        "Automation of Repetitive Tasks",
        "Enhanced Decision-Making",
        "Personalization of UX and Services",
        "Predictive Analytics"
      ],
      image: aisolution,
      show: false,
      tech: [
        // {
        //   techImg: Amazon_SageMaker,
        //   techName: "Amazon SageMaker",
        //   about: "A.I Solution"
        // },
        {
          techImg: Azure_ML,
          techName: "Azure ML",
          about: "A.I Solution"
        },
        {
          techImg: chatGPT,
          techName: "chatGPT",
          about: "A.I Solution"
        },
        {
          techImg: Google_Cloud_AI,
          techName: "Google Cloud AI",
          about: "A.I Solution"
        },
        {
          techImg: H2O,
          techName: "H2O",
          about: "A.I Solution"
        },
        {
          techImg: IBM_Watson,
          techName: "IBM Watson",
          about: "A.I Solution"
        },
        {
          techImg: Keras,
          techName: "Keras",
          about: "A.I Solution"
        },
        {
          techImg: PyTorch,
          techName: "PyTorch",
          about: "A.I Solution"
        },
        {
          techImg: Scikit_learn,
          techName: "Scikit Learn",
          about: "A.I Solution"
        },
        {
          techImg: TF,
          techName: "TF",
          about: "A.I Solution"
        },
      ]
    },
    {
      name: "Workflow Automation",
      help: [
        "Increased Efficiency and Productivity",
        "Improved Accuracy and Consistency",
        "Enhanced Collab. and Communication",
        "Scalability and Flexibility"
      ],
      image: workflow,
      show: false,
      tech: [
        // {
        //   techImg:alteryx,
        //   techName: "Alteryx",
        //   about: "Workflow Automation"
        // },
        {
          techImg: apache_airflow,
          techName: "Apache Airflow",
          about: "Workflow Automation"
        },
        // {
        //   techImg:Automation_Anywhere,
        //   techName: "Automation Anywhere",
        //   about: "Workflow Automation"
        // },
        // {
        //   techImg:blue_prism,
        //   techName: "Blue Prism",
        //   about: "Workflow Automation"
        // },
        {
          techImg: integromat,
          techName: "Integromat",
          about: "Workflow Automation"
        },
        // {
        //   techImg:Microsoft_Power_Automate,
        //   techName: "Microsoft Power Automate",
        //   about: "Workflow Automation"
        // },
        {
          techImg: Nintex,
          techName: "Nintex",
          about: "Workflow Automation"
        },
        {
          techImg: UiPath,
          techName: "UiPath",
          about: "Workflow Automation"
        },
        {
          techImg: Workato,
          techName: "Workato",
          about: "Workflow Automation"
        },
        {
          techImg: Zapier,
          techName: "Zapier",
          about: "Workflow Automation"
        },
      ]
    },
  ]);
  const [select, setSelect] = useState(null);
  useEffect(() => {
    let data = content.filter((item) => item.show === true);
    setSelect(data);
  }, [content]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 900, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 780, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ],
  };

  const controlsDataName = useAnimationControls();

  const [activePosition, setActivePosition] = useState(0);
  const [animation, setAnimation] = useState({ current: 0, previous: null });
  const [isVisible, setIsVisible] = useState(true);

  const onClickHandler = (position) => {
    setContent((prevContent) =>
      prevContent.map((item, index) => ({
        ...item,
        id: index,
        show: index === position,
      }))
    );
    setAnimation((prevAnimation) => ({
      current: position,
      previous: prevAnimation.current,
    }));

    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 250);
  };

  const ref = useRef(null);
  useAnimationFrame((time) => {
    if (ref.current) {
      ref.current.style.transform = `rotateX(${time * 0.08}deg)`;
    }
  });

  // const onScrollHandler = (e) => {
  //   console.log("scroll detected");
  //   if (e.deltaY < 0) {

  //     if (activePosition > 0) {
  //       setActivePosition(p => p-1)
  //       onClickHandler(activePosition);
  //     }
  //   } else if (e.deltaY > 0) {
  //     // Scroll down
  //     if (activePosition < 5) {
  //       setActivePosition(p => p+1)
  //       onClickHandler(activePosition);
  //     }
  //   }
  // };

  // useEffect(() => {
  // controlsDataName.stop("close")
  // if (activePosition !== animation.previous) {
  // controlsDataName.start("open");
  // } else {
  // controlsDataName.start("close");
  // }

  // return () => {
  //   controlsDataName.stop()
  // }
  // }, [animation, activePosition, content]);
  // window.addEventListener("wheel", onScrollHandler);
  return (
    <React.Fragment>
      <div className=" font-poppins w-[95%] mx-auto">
        <h2 className=" font-semibold text-center text-xl mt-20 font-poppins">
          <span className=" text-[#20AC18]">Algo</span>Flow AI Presents
        </h2>

        <div className="  grid grid-cols-12 lg:max-w-[90%] mx-auto">
          <div className=" grid lg:grid-cols-3 space-y-5 lg:space-y-0 col-span-3 ">
            <ServicesRoundButton
              position={0}
              buttonImage={uiux}
              text={[" UI / UX", "Development"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-7}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />

            <ServicesRoundButton
              position={1}
              buttonImage={appdev}
              text={["Application", "Development"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-110}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />

            <ServicesRoundButton
              position={2}
              buttonImage={webdev}
              text={["Website", "Development"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-212}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />
          </div>

          <h1 className=" text-[#20AC18] mt-2 sm:mt-0 text-[16px] sm:text-xl md:text-3xl font-semibold  text-center mx-auto col-span-6">
            Premium Services..
          </h1>

          <div className=" grid lg:grid-cols-3 space-y-5 lg:space-y-0 col-span-3 ">
            <ServicesRoundButton
              position={3}
              buttonImage={crm}
              text={["CRM", "Integration"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-930}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />

            <ServicesRoundButton
              position={4}
              buttonImage={aisolution}
              text={["AI", "Solution"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-1035}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />

            <ServicesRoundButton
              position={5}
              buttonImage={workflow}
              text={["Workflow", "Automation"]}
              content={content}
              onClickHandler={onClickHandler}
              positionX={-1139}
              positionY={177}
              activePosition={activePosition}
              setActivePosition={setActivePosition}
            />
          </div>
        </div>

        <div className=" max-w-[95%] lg:max-w-[90%] mx-auto mt-8 lg:mt-16 grid grid-cols-1 lg:grid-cols-2">
          {select &&
            select.map((data, index) => {
              return (
                <div key={index} className="flex flex-row space-x-12 lg:space-x-16">
                  <AnimatePresence layout>
                    {isVisible ? (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        ref={ref}
                        className="h-12 w-12 mt-3 rounded-full ring-4 ring-[#20AC18] bg-cover bg-no-repeat bg-center mr-0 lg:mr-5 transition-all ease-in-out duration-300 ml-4 sm:ml-3 lg:ml-6"
                        style={{
                          backgroundImage: `url(${data.image})`,
                          margin: "20px",
                          boxShadow: `
                         0 0 0 5px rgba(255, 255, 255, 1), 
                          0 0 0 6px rgba(255, 255, 255, 1), 
                          0 0 0 7px rgba(34, 139, 34, 1), 
                          0 0 0 8px rgba(34, 139, 34, 0.5), 
                          0 0 0 15px rgba(34, 139, 34, 0.25)
                        `,
                        }}
                      ></motion.div>
                    ) : (
                      <div
                        className="h-12 w-12 mt-3 rounded-full ring-4 ring-[#20AC18] bg-cover bg-no-repeat bg-center mr-0 lg:mr-5 transition-all ease-in-out duration-300 ml-4 sm:ml-3 lg:ml-6"
                        style={{
                          // backgroundImage: `url(${data.image})`,
                          margin: "20px",
                          boxShadow: `
                          0 0 0 5px rgba(255, 255, 255, 1), 
                          0 0 0 6px rgba(255, 255, 255, 1), 
                          0 0 0 7px rgba(34, 139, 34, 1), 
                          0 0 0 8px rgba(34, 139, 34, 0.5), 
                          0 0 0 15px rgba(34, 139, 34, 0.25)
                        `,
                        }}
                        ref={ref}
                      ></div>
                    )}
                  </AnimatePresence>

                  {/* name */}
                  <div className=" mx-auto w-[70%] sm:w-[65%] md:w-[54%]">
                    <div className=" mt-5 -ml-4">
                      <AnimatePresence>
                        {isVisible ? (
                          <motion.h2
                            whileHover={{ scale: 1.2 }}
                            initial={{ opacity: 0, x: 0, y: -13 }}
                            animate={{ opacity: 1, x: 0, y: 0 }}
                            exit={{
                              opacity: 0,
                              transition: {
                                duration: 1.0,
                              },
                            }}
                            className=" text-lg sm:text-xl lg:text-2xl font-bold transition-all ease-linear duration-500 "
                          >
                            {data.name}
                          </motion.h2>
                        ) : (
                          <div
                            className=" text-2xl"
                            style={{ visibility: "hidden" }}
                          >
                            {data.name}
                          </div>
                        )}
                      </AnimatePresence>
                      <p className="  text-xs mt-2 ml-1">
                        With <span className="text-[#20B218] ">Algo</span>Flow
                        AI Exclusivity
                      </p>
                    </div>
                    {/* helps provided with section */}

                    <div>
                      <h3 className=" text-[#20B218] text-xs lg:text-sm -ml-3 lg:-ml-4 mt-5">
                        Helps Provided With
                      </h3>

                      <motion.div>
                        <AnimatePresence>
                          {isVisible ? (
                            <motion.ul
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{
                                opacity: 0,
                                transition: {
                                  duration: 0.5,
                                },
                              }}
                              className="font-medium text-xs md:text-sm list-disc mt-5"
                            >
                              {data.help.map((item, index) => (
                                <motion.li
                                  key={index}
                                  className="transition-all ease-in-out duration-500y"
                                >
                                  {item}
                                </motion.li>
                              ))}
                            </motion.ul>
                          ) : (
                            <motion.ul
                              initial={{ opacity: 0 }}
                              className="font-medium text-xs md:text-sm list-disc mt-5"
                            >
                              {data.help.map((item, index) => (
                                <motion.li
                                  key={index}
                                  className="transition-opacity duration-500"
                                >
                                  {/* {item} */}
                                </motion.li>
                              ))}
                            </motion.ul>
                          )}
                        </AnimatePresence>
                      </motion.div>
                    </div>

                    <div className=" flex flex-row justify-between mt-8">
                      <button className="py-2 px-4 md:py-3 md:px-7 bg-[#20AC18] bg-opacity-30 rounded-xl text-[10px] sm:text-xs font-inter font-medium text-black"  onClick={()=>{
                             props.openForm();
                      }}>
                        Get Quotation
                      </button>
                      <a href={Portfolio} download>
                      <button className="py-2 px-4 md:py-3 md:px-7 border border-black rounded-xl text-[10px] sm:text-xs font-inter font-medium text-[#138C0D] bg-opacity-30 bg-white">
                        Check Portfolio
                      </button>
                      </a>
                    </div>
                    <button className=" rounded-xl text-xs font-inter font-medium bg-[#B6FFFF] w-[100%] py-3 mt-4"  onClick={()=>{
                             props.openForm();
                      }}>
                      Ask For Free Application Review
                    </button>
                  </div>
                </div>
              );
            })}

          <div className="flex flex-col  justify-center max-w-[95%] mx-auto">
            <h3 className="text-lg lg:text-sm text-start font-medium lg:font-normal ml-0 lg:ml-10 mt-10 lg:mt-0">
              <span className="text-[#20B218]">Technology</span> We Use
            </h3>

            {/* tech stack carousel */}
            {isVisible ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: 0.5,
                  },
                }}
              >
                <Slider {...settings}>
                  {select &&
                    select[0].tech.map((data, index) => (
                      <div key={index} className=" mt-5 ">
                        <div className="  flex flex-col justify-center items-center w-[90%] sm:w-[93%] h-[100%] shadow-md p-1 sm:p-2  py-6 sm:py-7 rounded-xl space-y-2 mx-auto border border-gray-200">
                          <img src={data.techImg} alt="" className="h-[65px]" />
                          <h2 className=" font-semibold text-lg pt-4 sm:pt-6 text-gray-700 text-center">{data.techName}</h2>
                    <p className=" font-medium text-[12px] text-gray-400 text-center py-2 sm:py-3">{data.about}</p>
                        </div>
                      </div>
                    ))}
                </Slider>
              </motion.div>
            ) : (
              <>
                <Slider {...settings}>
                  {select &&
                    select[0].tech.map((data, index) => (
                      <div key={index} className=" mt-5 ">
                        <div className=" flex flex-col justify-center items-center w-[96%] h-[100%] shadow-lg p-2 py-7 rounded-xl space-y-2 mx-auto border border-gray-200">
                          <img alt="" className="h-[70px]" />
                          <h2
                            className=" font-semibold pt-4 text-gray-700 text-center"
                            style={{ visibility: "hidden" }}
                          >
                            <div>{data.techName}</div>
                          </h2>
                          <p
                            className=" font-medium text-[10px] text-gray-400 text-center"
                            style={{ visibility: "hidden" }}
                          >
                            {select[0].name}
                          </p>
                        </div>
                      </div>
                    ))}
                </Slider>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServicesModify;

export function ServicesRoundButton({
  position,
  buttonImage,
  text,
  content,
  onClickHandler,
  positionX,
  positionY,
  activePosition,
  setActivePosition,
}) {
  const controls = useAnimationControls();

  const handleClickEnter = () => {
    controls.start("show");
  };
  const handleClickExit = () => {
    controls.start("exit");
  };

  useEffect(() => {
    if (activePosition !== position) {
      handleClickExit();
    }
  }, [activePosition, position]);

  return (
    <>
      <motion.div
        whileHover={{ scale: 1.2 }}
        className={` text-center`}
        variants={{
          initial: {
            rotate: "0deg",
          },
          show: {
            x: positionX,
            y: positionY,
            opacity: 0,
            transition: {
              type: "spring",
              stiffness: 100,
              damping: 13,
              opacity: { duration: 1 },
              x: { duration: 0.5 },
              y: { duration: 0.5 },
            },
          },
          exit: {
            x: 0,
            y: 0,
            opacity: 1,
          },
        }}
        initial="initial"
        animate={controls}
        onClick={() => {
          onClickHandler(position);
          setActivePosition(position);
          handleClickEnter();
        }}
      >
        <div
          className={` h-12 w-12 rounded-full hover:cursor-pointer ring-4 ring-[#20AC18] bg-cover bg-no-repeat bg-center transition-all ease-in-out duration-500 mx-auto `}
          style={{ backgroundImage: `url(${buttonImage})` }}
        ></div>
        <p className=" text-xs font-semibold text-[#20AC18] mt-3">{text[0]} </p>
        <p className=" text-xs font-semibold ">{text[1]}</p>
      </motion.div>
    </>
  );
}

export const useAnimationFrame = (callback) => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(time, deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);
};