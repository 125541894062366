import React from "react"
import bmw from "../assets/Image/bmw.png";
import limitless from "../assets/Image/limitless.png";
import parkkey from "../assets/Image/parkkey.png";
import dairy from "../assets/Image/dairy.png";
import bg07 from "../assets/Image/bg07.png";
import bg08 from "../assets/Image/bg08.png";
import polygon from "../assets/Image/Polygon.png";
import { useState, useEffect } from "react";
import { motion, useScroll } from 'framer-motion';

const Company = () => {
    const [isMovedBMW, setIsMovedBMW] = useState(false);
    const [isMovedDiary, setIsMovedDiary] = useState(false);
    const [bmwhover, setbmw] = useState(false);
    const [parkkeyhover, setparkkey] = useState(false);
    const [dairyhover, setdairy] = useState(false);
    const [limitlesshover, setlimitless] = useState(false);
    const { scrollYProgress } = useScroll();
    // console.log(scrollYProgress);
    const toggleMove = () => {
        setIsMovedBMW(true);
        setIsMovedDiary(false);
        // console.log(scrollYProgress);
    };
    useEffect(() => {
        console.log(scrollYProgress);
    }, [scrollYProgress]);
    return (
        <React.Fragment>
            <div className=" font-poppins mt-16 max-w-[98%] mx-auto">
                <div
                    className="bg-cover bg-center relative mx-auto p-2 pb-16"
                // style={{ backgroundImage: `url(${bg07})` }}
                >
                    <h1 className="font-semibold text-xl sm:text-2xl text-center mt-16 -z-10">
                        Happy Client<span className="border-b-4 border-b-[#0A5906]"> We Worked With</span>
                    </h1>
                    <div className="grid grid-cols-4 place-content-center mt-24 max-w-[100%] lg:max-w-[80%] mx-auto">
                        <div className=" relative inline-block z-[999] my-auto">
                            <img
                                src={bmw}
                                alt="BMW"
                                className="w-[100px] lg:w-[160px] mx-auto my-auto hover:scale-110 transition-all ease-in-out duration-1000 hover:cursor-pointer relative "
                                onMouseEnter={() => setbmw(true)}
                                onMouseLeave={() => setbmw(false)}
                            />
                            {bmwhover && <div className="hidden lg:block absolute left-[53%] -top-[210%] w-[500%] sm:w-[450px] h-[160px] bg-white transform -translate-x-1/2 mb-2 text-sm shadow-xl rounded-xl transition-opacity p-6  border border-gray-200 ">
                                <h1 className=" font-medium  text-xs sm:text-sm text-end ">What They Wanna Speak<span className=" border-b-2 border-b-[#0A5906]"> About<span className=" text-[#20AC18]"> Algo</span>Flow AI</span>
                                </h1>
                                <h2 className="flex items-center justify-center  text-start text-xs sm:text-sm text-gray-800 font-medium mt-5">The AI service implemented in our hospital has significantly enhanced efficiency and patient care. The development team delivered a robust and reliable solution that exceeded our expectations. Excellent work!</h2>
                                <img src={polygon} alt="polygon" className=" w-16 absolute top-[95%] right-[45%] " />
                            </div>}
                        </div>
                        <div className=" relative inline-block">
                            <img
                                src={parkkey}
                                alt="Parkkey"
                                className="w-[100px] lg:w-[160px] mx-auto my-auto hover:scale-110 transition-all ease-in-out duration-1000 hover:cursor-pointer"
                                onMouseEnter={() => setparkkey(true)}
                                onMouseLeave={() => setparkkey(false)}
                            />
                            {parkkeyhover && <div className=" hidden lg:block absolute left-[53%] -top-[190%]  w-[480px] h-[160px] bg-white transform -translate-x-1/2 mb-2 text-sm shadow-xl rounded-xl transition-opacity p-6  border border-gray-200">
                                <h1 className=" font-medium text-sm text-end ">What They Wanna Speak<span className=" border-b-2 border-b-[#0A5906]"> About<span className=" text-[#20AC18]"> Algo</span>Flow AI</span>
                                </h1>
                                <h2 className="flex items-center justify-center  text-start text-xs sm:text-sm text-gray-800 font-medium mt-5">The parking management system is intuitive, reliable, and has greatly improved our parking operations. The team provided an excellent solution that met all our needs. Great job!</h2>
                                <img src={polygon} alt="polygon" className=" w-16 absolute top-[95%] right-[45%] " />
                            </div>}
                        </div>
                        <div className=" relative inline-block my-auto">
                            <img
                                src={limitless}
                                alt="Limitless"
                                className="w-[100px] lg:w-[160px] mx-auto my-auto hover:scale-110 transition-all ease-in-out duration-1000 hover:cursor-pointer"
                                onMouseEnter={() => setlimitless(true)}
                                onMouseLeave={() => setlimitless(false)}
                            />
                            {limitlesshover && <div className=" hidden lg:block absolute left-[53%] -top-[350%]  w-[450px] h-[160px] bg-white transform -translate-x-1/2 mb-2 text-sm shadow-xl rounded-xl transition-opacity p-6  border border-gray-200 ">
                                <h1 className=" font-medium text-sm text-end ">What They Wanna Speak<span className=" border-b-2 border-b-[#0A5906]"> About<span className=" text-[#20AC18]"> Algo</span>Flow AI</span>
                                </h1>
                                <h2 className="flex items-center justify-center  text-start text-xs sm:text-sm text-gray-800 font-medium mt-5">The AI service implemented in our hospital has significantly enhanced efficiency and patient care. The development team delivered a robust and reliable solution that exceeded our expectations. Excellent work!</h2>
                                <img src={polygon} alt="polygon" className=" w-16 absolute top-[95%] right-[45%] " />
                            </div>}
                        </div>
                        <div className=" relative inline-block my-auto">
                            <img
                                src={dairy}
                                alt="Dairy"
                                className="w-[65px] h-[65px] lg:w-[100px] lg:h-[100px] mx-auto my-auto hover:scale-110 transition-all ease-in-out duration-1000 hover:cursor-pointer"
                                onMouseEnter={() => setdairy(true)}
                                onMouseLeave={() => setdairy(false)}
                            />
                            {dairyhover && <div className=" hidden lg:block absolute left-[53%] -top-[200%]  right-10 w-[450px] h-[160px] bg-white transform -translate-x-1/2 mb-2 text-sm shadow-xl rounded-xl transition-opacity p-6  border border-gray-200 ">
                                <h1 className=" font-medium text-sm text-end ">What They Wanna Speak<span className=" border-b-2 border-b-[#0A5906]"> About<span className=" text-[#20AC18]"> Algo</span>Flow AI</span>
                                </h1>
                                <h2 className="flex items-center justify-center  text-start text-xs sm:text-sm text-gray-800 font-medium mt-5">The dairy management and delivery tracking application has streamlined our entire operation. It is user-friendly, efficient, and has greatly improved our delivery process. We are highly satisfied with the results!</h2>
                                <img src={polygon} alt="polygon" className=" w-16 absolute top-[95%] right-[45%] " />
                            </div>}
                        </div>


                    </div>
                </div>

            </div>
        </React.Fragment >
    )
}

export default Company;