import React from "react";
import msme from "../assets/Image/msme.png";
import makeInIndia from "../assets/Image/makeInIndia.png";
import gem from "../assets/Image/gem.png";
import line from "../assets/Image/line.png";
import react from "../assets/Image/react.png";
import java from "../assets/Image/java.png";
import ruby from "../assets/Image/rubby.png";
import Slider from "react-slick";
import angular from "../assets/Image/angular.png";
import django from "../assets/Image/django.png";
import Gatsby from "../assets/Image/Gatsby.png";
import laravel from "../assets/Image/laravel.png";
import nextjs from "../assets/Image/nextjs.png";
import nodejs from "../assets/Image/nodejs.png";
import rails from "../assets/Image/rails.png";
import spring from "../assets/Image/spring.png";
import vuejs from "../assets/Image/vuejs.png";
import bg08 from "../assets/Image/bg08.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Technologies = () => {

    const tech = [
        {
            image: react,
            name: "React",
            about: "For Web & Mobile Development"
        },
        {
            image: java,
            name: "Java",
            about: "For Web & Mobile Development"
        },
        {
            image: ruby,
            name: "Ruby",
            about: "For Web & Mobile Desinging"
        },
        {
            image: angular,
            name: "Angular",
            about: "For Web & Mobile Development"
        },
        {
            image: django,
            name: "Django",
            about: "For Web & Mobile Development"
        },
        {
            image: nextjs,
            name: "Next Js",
            about: "For Web & Mobile Development"
        },
        {
            image: nodejs,
            name: "Node Js",
            about: "For Web & Mobile Desinging"
        },
        {
            image: Gatsby,
            name: "Gatsby",
            about: "For Web & Mobile Development"
        },
        {
            image: laravel,
            name: "Laravel",
            about: "For Web & Mobile Desinging"
        },
        {
            image: rails,
            name: "Rails",
            about: "For Web & Mobile Desinging"
        },
        {
            image: vuejs,
            name: "Vue Js",
            about: "For Web & Mobile Desinging"
        },
        {
            image: spring,
            name: "Spring",
            about: "For Web & Mobile Desinging"
        },
    ]
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 1 } },
            { breakpoint: 900, settings: { slidesToShow: 3, slidesToScroll: 1 } },
            { breakpoint: 780, settings: { slidesToShow: 2, slidesToScroll: 1 } }
        ]
    };
    return (
        <React.Fragment>
            <div className=" font-poppins mt-16">
                    <h1 className=" font-semibold text-xl sm:text-2xl text-center ">We Are Rec<span className=" border-b-4 border-b-[#0A5906]">ognized By</span></h1>
                    <div className=" grid grid-cols-3 mt-10">
                        <img src={makeInIndia} alt="makeInIndia" className="  h-[50px]  md:h-[70px] lg:h-[90px] mx-auto" />
                        <img src={msme} alt="msme" className=" h-[50px]  md:h-[70px] lg:h-[90px] mx-auto" />
                        <img src={gem} alt="gem" className=" h-[50px]  md:h-[70px] lg:h-[90px] mx-auto" />
                    </div>


                    <h1 className=" font-semibold text-xl sm:text-2xl text-center mt-16">Technology Us<span className=" border-b-4 border-b-[#0A5906]">ed By <span className=" text-[#20AC18]">Algo</span>Flow AI</span>
                    </h1>

                    <div className=" max-w-[85%] sm:max-w-[90%] mx-auto mt-5 lg:mt-10 backdrop-blur-sm ">
                        <Slider {...settings}>
                            {tech.map((data, index) => (
                                <div key={index} className="mt-10  ">
                                    <div className=" flex flex-col justify-center items-center w-[90%] h-[100%] shadow-md p-2 py-7 rounded-xl space-y-2 mx-auto border border-gray-200 bg-white">
                                        <img src={data.image} alt="" className="h-[65px]" />
                                        <h2 className=" font-semibold text-lg pt-3 text-gray-700 text-center">{data.name}</h2>
                                        <p className=" font-medium text-[12px] text-gray-500 text-center py-3">{data.about}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                {/* </div> */}
            </div>
        </React.Fragment>
    )
}

export default Technologies;