import React from "react";
import bg02 from "../assets/Image/bg02.png";
import bg06 from "../assets/Image/bg06.png";
import ellipse01 from "../assets/Image/ellipse01.png";
import ellipse02 from "../assets/Image/ellipse02.png";
import ellipse03 from "../assets/Image/ellipse03.png";
import arc01 from "../assets/Image/arc01.png";
import arc02 from "../assets/Image/arc02.png";
import arc03 from "../assets/Image/arc03.png";
import person from "../assets/Image/person.png"
import divyanshi from "../assets/Image/divyanshi.png";
import divyanshi01 from "../assets/Image/LeadersImage/divyanshiSingh03.png";
import saurabh from "../assets/Image/LeadersImage/saurabh.png";
import tatsat from "../assets/Image/LeadersImage/tatsat03.png";
import pradeep from "../assets/Image/LeadersImage/pradeep.png";
import gaurav from "../assets/Image/LeadersImage/gaurav.png";
import akash from "../assets/Image/LeadersImage/akash.png";
import sagar from "../assets/Image/LeadersImage/sagar.png";
import mohit from "../assets/Image/LeadersImage/mohit.png";
import Dalbir_Singh from "../assets/Image/LeadersImage/Dalbir_Singh.png";
import seema_singh from "../assets/Image/LeadersImage/seema_singh.png";

const Leadership = (props) => {
    const leadership = [
        {
            bgimage: ellipse01,
            arc: arc01,
            image:Dalbir_Singh,
            name: "Dalbir Singh",
            designation: "Executive director"
        },
        {
            bgimage: ellipse02,
            arc: arc02,
            image:seema_singh,
            name: "Seema Singh",
            designation: "Executive director"
        },
        {
            bgimage: ellipse03,
            arc: arc03,
            image: saurabh,
            name: "Saurabh Maurya",
            designation: "Chief Technology Officer"
        },
        {
            bgimage: ellipse01,
            arc: arc01,
            image: tatsat,
            name: "Tatsat Pandey",
            designation: "Engineering Manager"
        },
        {
            bgimage: ellipse01,
            arc: arc01,
            image: pradeep,
            name: "Pradeep Singh",
            designation: "Project Manager"
        },
        {
            bgimage: ellipse02,
            arc: arc02,
            image: akash,
            name: "Akash Singh",
            designation: "Full Stack Developer"
        },
        {
            bgimage: ellipse03,
            arc: arc03,
            image: sagar,
            name: "Sagar Singh Rawal",
            designation: "Software Developer I"
        },
        {
            bgimage: ellipse01,
            arc: arc01,
            image: mohit,
            name: "Mohit Verma",
            designation: "Frontend Developer"
        },
    ]
    return (
        <React.Fragment>
            <div className="font-poppins">
                <h2 className="font-semibold text-center text-xl mt-16 lg:mt-8 font-poppins">
                    <span className="text-[#20AC18]">Algo</span>Flow AI
                </h2>
                <h1 className=" text-[#0A5906] text-4xl font-semibold text-center pb-8 "> The Leaderships</h1>

                <div className=" my-24 grid grid-cols-2 md:grid-cols-4 gap-y-28 max-w-5xl mx-auto">
                    {
                        leadership.map((data, index) => {
                            return (
                                <div className=" text-start">
                                    <div class=" w-[160px] h-[150px] bg-cover bg-center relative mx-auto" style={{ backgroundImage: `url(${data.bgimage})` }}>
                                        <img src={data.image} alt="person" className=" z-20 rounded-br-[60px]   h-[230px] absolute top-[-80px] left-0 " />
                                        <img src={data.arc} alt="arc01" className=" h-[60px] z-10 absolute top-[-6px] left-[-8px]" />
                                    </div>
                                    <div className=" w-[160px] mx-auto mt-4">
                                        <h2 className=" font-semibold text-sm uppercase">{data.name}</h2>
                                        <p className=" font-medium text-xs text-gray-700 mt-1">{data.designation}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


            </div>
        </React.Fragment>

    )
}

export default Leadership;