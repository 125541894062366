import React, { useState } from 'react';
import logo from "../assets/Image/logo.png";
// import { motion } from "framer-motion";
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
const Navbar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <nav className=" sticky top-0 z-[9999] font-inter lg:px-16  ">
            <div className="mx-auto">
                <div className="font-inter flex flex-row justify-between items-center backdrop-blur-sm rounded-t-2xl py-12 lg:py-6 px-4">
                    <img src={logo} alt="logo" className=' h-8 lg:h-16 ' />
                    <div className="hidden md:block ">
                        <ul className=" flex flex-row space-x-5">
                            <li> <Link activeClass="active" to="home" spy={true} smooth={true} className=' hover:cursor-pointer'> Home </Link></li>
                            <li
                                className=' hover:cursor-pointer'><NavLink to="/ai">Products</NavLink></li>
                            <li> <Link activeClass="active" to="service" spy={true} smooth={true} className=' hover:cursor-pointer'> Services </Link></li>
                            <li> <Link activeClass="active" to="aboutus" spy={true} smooth={true} className=' hover:cursor-pointer'> About Us </Link></li>
                            <li> <Link activeClass="active" to="contactus" spy={true} smooth={true} className=' hover:cursor-pointer'> Contact Us </Link></li>
                        </ul>
                    </div>
                    <div className=' flex flex-row space-x-2'>
                        <a className=" bg-white rounded-xl px-6 py-3 text-sm font-medium hover:cursor-pointer" onClick={() => {
                            props.openForm();
                        }}>Get Quotation</a>

                        <div className="-mr-2 flex md:hidden z-20">
                            <button
                                onClick={toggleMenu}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg
                                    className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${isOpen ? 'block' : 'hidden'} md:hidden `}>
                <ul className=" flex flex-col space-y-5 bg-white py-5 absolute top-32 w-full -z-10">

                    <li> <Link activeClass="active" to="home" spy={true} smooth={true} className=' hover:cursor-pointer pl-4 font-medium'> Home </Link></li>
                    <li className=' hover:cursor-pointer pl-4 font-medium'><NavLink to="/ai">Products</NavLink></li>
                    <li> <Link activeClass="active" to="service" spy={true} smooth={true} className=' hover:cursor-pointer pl-4 font-medium'> Services </Link></li>
                    <li> <Link activeClass="active" to="aboutus" spy={true} smooth={true} className=' hover:cursor-pointer pl-4 font-medium'> About Us </Link></li>
                    <li> <Link activeClass="active" to="contactus" spy={true} smooth={true} className=' hover:cursor-pointer pl-4 font-medium'> Contact Us </Link></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;