import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import computerVisionImage from "../assets/Image/AIPage/aipowered.png";
import nlpImage from "../assets/Image/AIPage/aiNLP.png";
import aiAutomationImage from "../assets/Image/AIPage/aiautomation.png";
import predicitveai from "../assets/Image/AIPage/predictiveAI.png";
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";
import home from '../assets/Image/home.png';
const AI = () => {
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start start", "end end"],
    });

    const services = [
        {
            number: "01",
            title: "Computer Vision",
            items: [
                "Object Detection",
                "Image Classification",
                "Facial Recognition",
                "Image Segmentation",
                "Optical Character Recognition",
                "Video Analytics",
                "Gesture Recognition",
            ],
            image: computerVisionImage,
        },
        {
            number: "02",
            title: "Natural Language Processing",
            items: [
                "Conversational AI",
                "Text Classification",
                "Sentiment Analysis",
                "Question Answering Systems",
                "Speech Recognition and Synthesis",
                "Custom Chat GPT",
            ],
            image: nlpImage,
        },
        {
            number: "03",
            title: "AI-powered Automation",
            items: [
                "Intelligent Document Processing",
                "Workflow Automation",
                "Chatbot Development",
                "Virtual Assistants",
                "Smart Data Extraction",
            ],
            image: aiAutomationImage,
        },
        {
            number: "04",
            title: "Predictive Analytics",
            items: [
                "Predictive Modeling",
                " Customer Churn Prediction",
                " Sales and Demand Forecasting",
                " Risk Assessment and Management",
                " Operational Cost Reduction",
                "  Marketing Campaign Optimization",
            ],
            image: predicitveai,
        },
    ];

    const xTransforms = [
        useTransform(scrollYProgress, [0, 0.15, 0.5, 0.75, 1], [-300, 0, 0, 0, 0]),
        useTransform(scrollYProgress, [0, 0.25, 0.5, 0.75, 1], [300, 0, 0, 0, 0]),
        useTransform(scrollYProgress, [0, 0.25, 0.5, 0.75, 1], [0, -300, 0, 0, 0]),
        useTransform(scrollYProgress, [0, 0.25, 0.5, 0.9, 1], [0, 0, 300, 0, 0]),
    ];

    const opacityImages = [
        useTransform(
            scrollYProgress,
            [0, 0.1, 0.4, 0.6, 0.9, 1],
            [0, 1, 1, 0, 0, 0]
        ),
        useTransform(
            scrollYProgress,
            [0, 0.1, 0.4, 0.6, 0.9, 1],
            [0, 0, 1, 1, 0, 0]
        ),
        useTransform(
            scrollYProgress,
            [0, 0.1, 0.4, 0.6, 0.9, 1],
            [0, 0, 0, 1, 1, 0]
        ),
        useTransform(
            scrollYProgress,
            [0, 0.1, 0.4, 0.6, 0.9, 1],
            [0, 0, 0, 0, 1, 1]
        ),
    ];


    const { ref: ref1, inView: inView1 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: ref2, inView: inView2 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const { ref: ref3, inView: inView3 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    return (
        <React.Fragment>
            <div
                ref={containerRef}
                className="bg-[#646464] text-white min-h-screen overflow-hidden pb-8 font-poppins "
            >
                <div className=" flex flex-row justify-evenly items-center py-2 px-3 bg-[#4b4848] rounded-xl w-[50%] md:w-[25%] lg:w-[15%] mt-4 ml-2 shadow-lg ">
                    <img src={home} alt="home" className=" w-6" />
                    <Link to="/" className="" >Back to Home</Link>
                </div>
                <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#85FFBD] to-[#FFFB7D] py-2 px-6 rounded-lg mt-10 text-center">
                    Artificial Intelligence Software Services
                </h1>
                <div className=" hidden lg:block relative mt-28">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            className="sticky top-0 min-h-[60vh] flex items-center justify-center"
                        >
                            <div className="max-w-[90%] mx-auto px-4 flex flex-row items-center space-y-4">
                                {index % 2 === 0 ? (
                                    <>
                                        <div className=" ">
                                            <div className="">
                                                <h2 className=" font-bold text-gray-700 text-6xl absolute -top-[5%] opacity-70">
                                                    {service.number}
                                                </h2>
                                                <h3 className="text-4xl font-bold">{service.title}</h3>
                                                <ul className="list-disc list-inside space-y-2 mt-8 opacity-80">
                                                    {service.items.map((item, i) => (
                                                        <li key={i} className="">
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className=" h-[50%]">
                                            <motion.img
                                                src={service.image}
                                                alt={service.title}
                                                className="w-[200%] h-auto "
                                                style={{
                                                    opacity: opacityImages[index],
                                                    x: xTransforms[index],
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className=" h-[50%] ">
                                            <motion.img
                                                src={service.image}
                                                alt={service.title}
                                                className="w-[200%] h-auto"
                                                style={{
                                                    opacity: opacityImages[index],
                                                    x: xTransforms[index],
                                                }}
                                            />
                                        </div>
                                        <div className="md:w-1/2 mb-8 md:mb-0">
                                            <h2 className="font-bold text-gray-700 text-6xl absolute -top-[5%] opacity-70">
                                                {service.number}
                                            </h2>
                                            <h3 className="text-4xl font-bold">{service.title}</h3>
                                            <ul className="list-disc list-inside space-y-2 mt-8 opacity-80">
                                                {service.items.map((item, i) => (
                                                    <li key={i} className="">
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </motion.div>
                    ))}
                </div>



                <div className=" block lg:hidden relative mt-28">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            className="sticky top-0 min-h-[50vh] flex items-center justify-center mb-24"
                        >
                            <div className="max-w-[90%] mx-auto px-4 flex flex-col items-center space-y-4">
                                <div className="relative">
                                    <h2 className="font-bold text-gray-700 text-6xl absolute -top-[25%] opacity-70">
                                        {service.number}
                                    </h2>
                                    <h3 className="text-4xl font-bold">{service.title}</h3>
                                    <ul className="list-disc list-inside space-y-2 mt-8 opacity-80">
                                        {service.items.map((item, i) => (
                                            <li key={i} className="">
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="h-[50%]">
                                    <motion.img
                                        src={service.image}
                                        alt={service.title}
                                        className={`w-[200%] h-auto ${inView1 ? ` ${index % 2 === 0 ? ` animate-slideFromLeft` : ` animate-slideFromRight`}` : 'opacity-0'}`}
                                        ref={ref1}
                                        initial={{ opacity: 0, x: 100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ duration: 1 }}
                                    />
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default AI;