import './App.css';
import Home from './component/Home';
import AI from './component/AI';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState } from 'react';


function App() {
  return (
    <div className="App">  
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/ai' element={<AI/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
