import React from "react";
import Navbar from "./Navbar";
import bg from "../assets/Image/bg01.jpg";
import card1 from "../assets/Image/card1.png";
import card2 from "../assets/Image/card02.png";
import greenbox from "../assets/Image/greenbox.png";
import arrow from "../assets/Image/arrow01.png";
import logo from "../assets/Image/logo.png";
import bg03 from "../assets/Image/bg03.png";
import bg04 from "../assets/Image/bg04.png";
import bg05 from "../assets/Image/bg05.png";
import toast, { Toaster } from 'react-hot-toast';
import Modal from "./Modal.jsx";
import Services from "./Services.jsx";
import Leadership from "./Leadership.jsx";
import Technologies from "./Teachnologies.jsx";
import Company from "./Company.jsx";
import Industries from "./Industries.jsx";
import Customer from "./Customer.jsx";
import Footer from "./Footer.jsx";
import ServicesModify from "./ServicesModify.jsx";
import AboutUs from "./AboutUs.jsx";
import { useState, useEffect } from "react";
import { Element } from 'react-scroll';
const Home = (props) => {
    const [form, setForm] = useState(false);
    const fromSubmitted = () => {
        toast.success('Your request is successfully submitted', {
            duration: 10000,
            style: {
                fontFamily: 'Poppins, sans-serif',
                backgroundColor: '#0A5906',
                color: 'white'
            }
        });

    }
    const openForm = () => {
        setForm(true);
        setOpen(true);
    }
    const closeForm = () => {
        setForm(false);
        setOpen(true);
    }
    const [open, setOpen] = useState(false);
    const closeloader = () => {
        setOpen(false);
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpen(true);
        }, 15000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <React.Fragment >
            <Element name="home">
                <div className="w-full h-screen flex items-center justify-center lg:p-4 lg:px-6 pb-10">
                    <div className=" absolute bg-cover bg-center bg-no-repeat w-[50%]  h-[50%] bottom-[-20px] left-[-20px] rounded-2xl font-inter -z-40  "
                        style={{ backgroundImage: `url(${greenbox})` }}>
                    </div>

                    <div
                        className="relative bg-cover bg-center bg-no-repeat w-full h-screen  md:h-full rounded-2xl font-inter  z-50"
                        style={{ backgroundImage: `url(${bg})` }}
                    >
                        <div className="absolute inset-0 bg-gray-300 opacity-40 rounded-2xl"></div>
                        <div className="relative z-10 h-full w-full">
                            <div className="" >
                                <Navbar openForm={openForm} />
                            </div>
                            <div className=" lg:grid lg:grid-cols-12 gap-x-28  m-6 lg:m-0 mt-2 lg:mt-5">
                                <div className=" col-span-6">
                                </div>
                                <div className="text-center col-span-6">
                                    <h1 className="font-semibold text-3xl md:text-4xl text-start ">
                                        <span className=" bg-white bg-opacity-50 p-2  pt-4 rounded-xl">
                                            <span className="text-[#20AC18]  font-semibold">Algo</span>
                                            <span className="text-black font-semibold">Flow AI</span>
                                        </span>
                                    </h1>
                                    <h1 className=" text-3xl sm:text-4xl md:text-5xl font-bold flex flex-col text-start mt-4">
                                        <span className="  p-2 ">
                                            <span className="bg-white bg-opacity-50 rounded-xl">Your Dreams</span>
                                            <span className="text-[#0A5906] bg-white bg-opacity-50 rounded-xl pb-2"><br />
                                                Our Resp<span className="underline  decoration-4 decoration-orange-400  underline-offset-8">onsibility.</span></span>
                                        </span>
                                    </h1>

                                    <div className="sm:grid sm:grid-cols-8 lg:grid-cols-10 gap-x-7 lg:gap-x-10 mt-5 lg:mt-10 text-sm">
                                        <div className=" pb-5 sm:pb-7 bg-white shadow-2xl rounded-2xl flex flex-col items-center col-span-4 mb-5 sm:mb-0 max-w-[65%] sm:max-w-[100%] ">
                                            <img src={card2} alt="card2" className="h-[70px] sm:h-[90px]" />
                                            <h2 className="text-[#076C02] opacity-50 text-xl sm:text-3xl font-bold">99.9%</h2>
                                            <p className="font-bold mt-1 sm:mt-2 text-xs">
                                                <span className="text-[#0A5906] opacity-100">Alog</span>Flow AI, Our Satisfied Clients..
                                            </p>
                                            <p className="font-semibold bg-[#20AC18] rounded-md bg-opacity-50 p-3 text-black text-opacity-100 mt-2 sm:mt-5 hover:cursor-pointer" onClick={() => {
                                                openForm();
                                            }}>
                                                Get Quot For Your Brand
                                            </p>
                                        </div>

                                        <div className="pb-5 sm:pb-7 bg-white shadow-2xl rounded-2xl flex flex-col items-center col-span-4 max-w-[65%] sm:max-w-[100%] ">
                                            <img src={card1} alt="card1" className="h-[70px] sm:h-[90px]" />
                                            <h2 className="text-[#076C02] opacity-50 text-xl sm:text-3xl font-bold">150+</h2>
                                            <p className="font-bold mt-1 sm:mt-2 text-xs">
                                                <span className="text-[#0A5906] opacity-100">Algo</span>Flow AI, Till Worked With..
                                            </p>
                                            <p className="font-semibold bg-[#20AC18] rounded-md bg-opacity-50 p-3 mt-2 sm:mt-5 text-black text-opacity-100 hover:cursor-pointer" onClick={() => {
                                                openForm();
                                            }}>
                                                Browse Our Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(open && form) && (<div className="fixed inset-0  z-[99999] flex items-center justify-center backdrop-blur-sm animate-slideFromRight">
                        <div className={`fixed inset-0 bg-white flex lg:max-w-[80%] lg:max-h-[92%] my-auto mx-auto items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none shadow-2xl  transition-all ease-linear duration-400 `}>
                            <div className="relative mx-auto  ">
                                <Modal closeloader={closeloader} fromSubmitted={fromSubmitted} />
                            </div>
                            <button className=" absolute top-0 right-0 m-3 text-gray-500 hover:text-gray-900 " onClick={() => {
                                setOpen(false);
                                closeForm();
                            }}>
                                <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>)}

                    <Toaster />
                </div>
                <div
                    className=" relative rounded-2xl bg-cover bg-center bg-no-repeat mx-auto flex flex-col justify-center items-center space-y-6 pt-20 pb-10 mt-16"
                // style={{
                //     backgroundImage: `url(${bg02})`,
                //     backgroundSize: 'cover',
                //     backgroundPosition: 'center',
                //     backgroundRepeat: 'no-repeat'
                // }}
                >
                    <img src={bg03} alt="bg03" className=" absolute top-2 right-3 lg:right-32 lg:top-16 w-[100px]" />
                    <img src={bg04} alt="bg04" className=" absolute hidden lg:block lg:right-32 lg:bottom-16 w-[340px]" />
                    <img src={bg05} alt="bg05" className=" absolute top-0 left-3 lg:left-32  lg:bottom-16 w-[200px]" />
                    <h2 className="text-center font-extrabold text-2xl sm:text-3xl lg:text-5xl font-poppins">One Stop</h2>
                    <h2 className="text-center font-extrabold text-2xl sm:text-3xl lg:text-5xl font-poppins">Solutions For Developments.</h2>
                    <p className="text-center font-medium text-sm lg:text-base max-w-[70%] lg:max-w-[35%] mx-auto">One Stop Solutions For Developments Like Website Application Or Ai Related Software</p>
                    <div className="text-center">
                        <button className="font-medium text-base bg-[#0A5906] rounded-full py-4 px-16 text-white" onClick={() => {
                            openForm();
                        }}>Get Free Consultancy</  button>
                    </div>
                </div>
                <Element name="aboutus">
                    <AboutUs />
                </Element>
                {/* <Services /> */}
                <Element name="service">
                    <ServicesModify openForm={openForm} />
                </Element>
                <Technologies />
                {/* <Industries /> */}
                <Company />
                <Leadership openForm={openForm} />
                <Customer />
                <Element name="contactus">
                    <Footer />
                </Element>

            </Element>
        </React.Fragment>
    )
}
export default Home;