import React from "react";
import logo from "../assets/Image/logo.png";
import deskphone from "../assets/Image/deskphone.png";
import call from "../assets/Image/call.png";
import mail from "../assets/Image/mail.png";
import insta from "../assets/Image/insta.png";
import facebook from "../assets/Image/facebook.png";
import x from "../assets/Image/x.png";
import linkedin from "../assets/Image/linkedin.png";
const Footer = () => {
    return (
        <React.Fragment>
            <div className=" border border-gray-200 shadow-xl mt-10">
                <div className=" font-poppins grid grid-cols-12 gap-x-5 p-3 sm:p-4 md:p-6 lg:p-12 lg:px-20 ">
                    <div className=" col-span-12 lg:col-span-5  ">
                        <img src={logo} alt="logo" />
                        <p className=" text-sm font-medium mt-4"><span className=" text-[#0CC002]">Your</span> dreams, <span className="text-[#0CC002]">Our</span> responsibility</p>
                        <p className=" text-xs font-medium mt-4">Subscribe Newsletter</p>
                        <div className=" mt-5">
                            <input className=" text-opacity-60 text-xs font-medium py-3 px-2 lg:py-4 w-[65%] lg:w-[55%] lg:px-3 max-w-md bg-[#EFEFEF] text-gray-800 rounded-l-full  outline-none" placeholder="Enter Your Email Address" />
                            <button className=" text-gray-300 bg-[#0A5906] text-xs py-3 w-[35%] lg:w-[30%] px-5 lg:py-4 lg:px-10 rounded-es-full rounded-e-full -ml-6">Subscribe</button>
                        </div>

                        <p className=" text-xs mt-6 font-medium text-gray-400 max-w-md">By Entering Email Address Your Are Agreeing To All Of Our Mail Terms & Condition Rules And Allows Us To Send Emails.</p>
                    </div>

                    <div className="col-span-6 lg:col-span-3 mx-auto my-auto  ">
                        <div className="  flex flex-col space-y-3 ">
                            <div className=" flex flex-row  items-center">
                                <img src={mail} alt="deskphone" className=" w-3 lg:w-5 mr-2" />
                                <p className=" text-xs lg:text-sm">info@turbofinn.com</p>
                            </div>
                            <div className=" flex flex-row  items-center">
                                <img src={call} alt="deskphone" className=" w-3 lg:w-4 mr-2" />
                                <p className=" text-xs lg:text-sm">+91 9026433884</p>
                            </div>
                            <div className=" flex flex-row  items-center">
                                <img src={deskphone} alt="deskphone" className=" w-3 lg:w-5 mr-1" />
                                <p className=" text-xs lg:text-sm">+91 7452833648</p>
                            </div>
                        </div>

                        <div className=" flex flex-row space-x-7 mt-5">
                            <a href="https://www.instagram.com/turbofinn_ai/?igsh=MWIxdXQ2eWtxdzYzOQ%3D%3D" target="_blank"><img src={insta} alt="insta" className="w-6 hover:cursor-pointer " /> </a>
                            <a href="" target="_blank"><img src={facebook} alt="facebook" className="w-6 " /> </a>
                            <a href="https://www.linkedin.com/in/turbofinn-ai-a6ba392a0/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"><img src={linkedin} alt="linkedin" className="w-6 " /> </a>
                            <a href="/" target="_blank"><img src={x} alt="x" className="w-6 " /> </a>
                        </div>
                    </div>

                    {/* <div className="col-span-5 lg:col-span-2 py-5 lg:py-0 lg:mx-auto  my-auto ">
                        <h3 className=" font-medium ">Social Media</h3>
                        <div className="  flex flex-col space-y-4 mt-4">
                            <div className=" flex flex-row  items-center">
                                <img src={insta} alt="insta" className="w-4 mr-2" />
                                <p className=" text-sm">turbofinn_ai</p>
                            </div>
                            <div className=" flex flex-row  items-center">
                                <img src={facebook} alt="facebook" className="w-4 mr-2" />
                                <p className="  text-sm">turbofinn_ai</p>
                            </div>
                            <div className=" flex flex-row  items-center">
                                <img src={linkedin} alt="linkedin" className="w-4 mr-2" />
                                <p className=" text-sm">turbofinn_ai</p>
                            </div>
                            <div className=" flex flex-row  items-center">
                                <img src={x} alt="x" className="w-4 mr-2" />
                                <p className=" text-sm">turbofinn_ai</p>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-span-6 lg:col-span-4 py-5 lg:py-0  mx-auto my-auto ">
                        <h3 className=" font-medium">Office Address</h3>
                        <div className=" flex flex-col space-y-4  max-w-[280px] mt-3">
                            <p className="  max-w-xs text-xs md:text-sm font-medium">4th main, 6th Cross Rd, GM Palya, C V Raman Nagar, Bengaluru, Karnataka 560075</p>
                            <p className=" max-w-xs text-xs md:text-sm font-medium">3/5B, Gandhi Nagar, Mohanpuri Colony, Karaundi, Varanasi, Uttar Pradesh 221005</p>
                        </div>
                    </div>
                </div>

                <div className="  border-t border-black font-poppins flex flex-row justify-between items-center  p-4 lg:p-5 lg:px-20 font-medium text-[8px] sm:text-xs lg:text-sm">
                    <ul className=" flex flex-row  justify-evenly items-center space-x-4 lg:space-x-10 text-[#0A5906]">
                        <li>Privacy Policy</li>
                        <li>Terms & Conditions</li>
                        <li>Sitemap</li>
                    </ul>
                    <p>Copyright 2024 @ <span className=" text-[#15BB0A]">Algo</span>Flow Ai</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer;